import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Bradcam from "../components/bradcam"

const SellersPage = () => (
  <Layout>
    <SEO title="Sellers" />
    <Bradcam siteTitle="Sellers"></Bradcam>

    <div class="about_mission service-section">
        <div class="container">
            <div class="align-items-center">
                <div class="about_thumb">
                    <img src="/img/service/seller.png" alt="" />
                </div>
                <div>
              
              
<p><b>Meet with a Real Estate Professional:</b> There's no commitment required on your part for the initial meeting. It will be educational and help you identify your next steps. </p>
<p><b>Establish a Price:</b> Your agent will provide a market analysis, which will help you set an asking price.</p>
<p><b>Strategic Price:</b> As difficult as it may be, it's important to review the market analysis and consider your home price objectively. </p>
<p><b>Prepare Your Home:</b> View your home through the eyes of the buyer and ask yourself what you'd expect. Your agent will offer some useful suggestions. </p>
<p><b>List It For Sale:</b> When everything is in place your agent will put your home on the open market. It's critical you make it as easy as possible for potential buyers to view your home. </p>
<p><b>Showings:</b> Potential buyers may ask to see your home on short notice. It's best if you can accommodate these requests, you never want to miss a potential sale.  </p>
<p><b>Offers and Negotiation:</b> If everything goes well, a buyer and (most often the agent who represents them) will present your agent with an offer. </p>
<p><b>Choosing an Offer:</b> Your agent will present the benefits and risks of each offer. You will have the opportunity to either accept or counter any offer based on its merits. </p>
<p><b>Under Contract:</b> At this point, you and the buyer have agreed to all of the terms of the offer and both parties have signed the agreements.</p>
<p><b>Final Details:</b> While under contract, the buyer will work with their mortgage provider to finalize the loan and perform other due diligence.  </p>
<p><b>Inspection:</b> The buyer will usually perform a physical inspection of the home. They may even ask you to make certain repairs. Your agent will explain all of your options regarding the inspection. </p>
<p><b>Closing:</b> This is the transfer of funds and ownership. Depending on when the buyer moves into the home you will need to be all packed up and ready to move. </p>


                </div>
              

            </div>
        </div>
    </div>
  </Layout>
)

export default SellersPage
